import "./global.css";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useStyletron } from "baseui";
import { ChevronDown } from "baseui/icon";
import { AppNavBar, setItemActive, NavItem } from "baseui/app-nav-bar";
import PrivacyPolicy from "./PrivacyPolicy";
import Top from "./Top";
import Contact from "./Contact";
import Operator from "./Operator";

export default function App() {
  const [css] = useStyletron();
  const [primaryTab, setPrimaryTab] = useState("");

  useEffect(() => {
    ReactGA.initialize("G-4FQP8BT7B8");
    ReactGA.send({
      hitType: "pageview",
      page: `${primaryTab}`,
    });
  }, [primaryTab]);

  // const [secondaryTab, setSecondaryTab] = useState("");
  const [mainItems, setMainItems] = useState<NavItem[]>([
    { icon: ChevronDown, label: "新着記事" },
    {
      icon: ChevronDown,
      label: "プライバシーポリシー",
    },
    { icon: ChevronDown, label: "運営者情報" },
    {
      icon: ChevronDown,
      label: "お問い合わせ",
    },
  ]);

  function handleMainItemSelect(item: NavItem) {
    const { label } = item;
    setPrimaryTab(label);
    setMainItems((prev) => setItemActive(prev, item));
  }

  return (
    <React.Fragment>
      <div
        className={css({
          boxSizing: "border-box",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
        })}
      >
        <AppNavBar
          title="Diurna"
          mainItems={mainItems}
          onMainItemSelect={handleMainItemSelect}
        />
      </div>
      {(() => {
        switch (primaryTab) {
          case "":
            return <Top />;
          case "プライバシーポリシー":
            return <PrivacyPolicy primaryTab={primaryTab} />;
          case "運営者情報":
            return <Operator primaryTab={primaryTab} />;
          case "お問い合わせ":
            return <Contact primaryTab={primaryTab} />;
          default:
            return <Top />;
        }
      })()}
    </React.Fragment>
  );
}
