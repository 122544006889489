import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink as Link } from "baseui/link";

export interface BreadCrumbsProps {
  primaryTab: string;
}

export default function BreadCrumbs({ primaryTab }: BreadCrumbsProps) {
  const crumbs = [<Link href="/">トップページ</Link>];
  if (primaryTab) {
    crumbs.push(<Link href="#basic-subparent">{primaryTab}</Link>);
  }

  return <Breadcrumbs>{crumbs}</Breadcrumbs>;
}
