import { useStyletron } from "baseui";

export default function Top() {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        height: "100vh",
        backgroundImage: `url("/img/bg.png")`,
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      })}
    ></div>
  );
}
