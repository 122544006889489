import { useStyletron } from "baseui";
import { HeadingLevel, Heading } from "baseui/heading";
import BreadCrumbs, { BreadCrumbsProps } from "./BreadCrumbs";

export default function Operator(props: BreadCrumbsProps) {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        paddingTop: "10vh",
        width: "70vw",
        marginLeft: "auto",
        marginRight: "auto",
      })}
    >
      <BreadCrumbs {...props} />
      <div
        className={css({
          paddingTop: "5vh",
        })}
      >
        <HeadingLevel>
          <Heading>運営者情報</Heading>
        </HeadingLevel>
      </div>
    </div>
  );
}
