import { useState } from "react";
import { useStyletron } from "baseui";
import { HeadingLevel, Heading } from "baseui/heading";
import BreadCrumbs, { BreadCrumbsProps } from "./BreadCrumbs";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";

export default function Contact(props: BreadCrumbsProps) {
  const [css] = useStyletron();

  enum MailStatus {
    None,
    Valid,
    Invalid,
  }
  const regex =
    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  const isValidMailAddress = (mail: string) => regex.test(mail);
  const [mailAddress, setMailAddress] = useState("");
  const [mailAddressIsValid, setMailAddressIsValid] = useState(MailStatus.None);
  const modifyMailAddress = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = target;
    setMailAddress(value);
    const mailIsValid = isValidMailAddress(value);
    setMailAddressIsValid(mailIsValid ? MailStatus.Valid : MailStatus.Invalid);
    if (value === "") {
      setMailAddressIsValid(MailStatus.None);
    }
  };

  return (
    <div
      className={css({
        paddingTop: "10vh",
        width: "70vw",
        marginLeft: "auto",
        marginRight: "auto",
      })}
    >
      <BreadCrumbs {...props} />
      <div
        className={css({
          paddingTop: "5vh",
        })}
      >
        <HeadingLevel>
          <Heading>お問い合わせ</Heading>
        </HeadingLevel>
        <FormControl
          label="メールアドレス"
          caption="メールアドレスを入力してください"
          error={
            mailAddressIsValid === MailStatus.Invalid
              ? "メールアドレスが不正です"
              : ""
          }
          positive={
            mailAddressIsValid === MailStatus.Valid
              ? "利用可能なメールアドレスです"
              : ""
          }
        >
          <Input
            placeholder="example@gmail.com"
            value={mailAddress}
            onChange={(event) => modifyMailAddress(event)}
            error={mailAddressIsValid === MailStatus.Invalid}
            positive={mailAddressIsValid === MailStatus.Valid}
            clearable
          />
        </FormControl>
      </div>
    </div>
  );
}
