import { useStyletron } from "baseui";
import { StyledBody } from "baseui/card";
import { HeadingLevel, Heading } from "baseui/heading";
import BreadCrumbs, { BreadCrumbsProps } from "./BreadCrumbs";

export default function PrivacyPolicy(props: BreadCrumbsProps) {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        paddingTop: "10vh",
        width: "70vw",
        marginLeft: "auto",
        marginRight: "auto",
      })}
    >
      <BreadCrumbs {...props} />
      <div
        className={css({
          paddingTop: "5vh",
        })}
      >
        <HeadingLevel>
          <Heading>プライバシーボリシー</Heading>
          <StyledBody>
            この記事は当サイトにおける個人情報や取り扱いや免責事項などをまとめたものです。
          </StyledBody>
          <HeadingLevel>
            <Heading>個人情報の利用目的</Heading>
            <StyledBody>
              当サイトでは、メールでのお問い合わせやコメントの際に、お名前（ハンドルネーム）・メールアドレス等の個人情報をご登録いただく場合があります。
              これらの個人情報は、質問に対する回答や必要な情報をご連絡するために利用し、それ以外の目的では利用しません。
            </StyledBody>
            <Heading>個人情報の第三者への開示</Heading>
            <StyledBody>
              個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
              <br />
              <br />
              ・本人のご了解がある場合
              <br />
              ・法令等への協力のため、開示が必要となる場合
            </StyledBody>
            <Heading>個人情報の開示・訂正・追加・削除・利用停止</Heading>
            <StyledBody>
              個人情報の開示・訂正・追加・削除・利用停止をご希望の場合には、ご本人であることを確認したうえで、速やかに対応致します。
            </StyledBody>
            <Heading>Cookieについて</Heading>
            <StyledBody>
              当サイトでは、一部のコンテンツにおいてCookieを利用しています。
              Cookieとは、webコンテンツへのアクセスに関する情報であり、お名前・メールアドレス・住所・電話番号は含まれません。また、お使いのブラウザ設定からCookieを無効にすることが可能です。
            </StyledBody>
            <Heading>アクセス解析ツールについて</Heading>
            <StyledBody>
              当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
              このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
              Googleアナリティクスの詳細は「Googleアナリティクス利用規約」をご覧ください。
            </StyledBody>
            <Heading>広告の配信について</Heading>
            <StyledBody>
              当サイトでは、Google
              AdSenseによる広告サービスを利用しています。このような第三者配信事業者は、ユーザーの興味に応じた広告を配信するため、当サイトや他のブログ、サイトへのアクセス情報
              Cookie（氏名、住所、メールアドレス、電話番号等個人を特定するものではありません）を使用することがあります。第三者通信事業者に情報が利用されることを希望されない場合は、広告の設定やwww.aboutads.infoで広告を無効にしたり、Cookieを無効にすることができます。その他、Googleの広告におけるCookieの取り扱い詳細については、Googleのポリシーと規約ページをご覧ください。
            </StyledBody>
            <Heading>アフィリエイトについて</Heading>
            <StyledBody>
              当サイトは、各ASPの商品を紹介するアフィリエイトに参加しています。
              一部のリンクがアフィリエイトリンクとなっており、紹介した商品やサービスを購入いただくことで収益が発生します。
              当サイトは、Amazon.co.jpを宣伝しリンクすることにより、サイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
              当サイトは、Amazonのアソシエイトとして適格販売により収入を得ています。
            </StyledBody>
            <Heading>当サイトへのコメントについて</Heading>
            <StyledBody>
              以下の内容を含むコメントは運営者の裁量によって削除することがあります。
              <br />
              <br />
              ・特定の自然人または法人を誹謗し、中傷するもの
              <br />
              ・極度にわいせつな内容を含むもの
              <br />
              ・禁制品の取引に関するものや、他者を害する行為の依頼など、法律によって禁止されている物品、行為の依頼や斡旋などに関するもの
              <br />
              ・その他、公序良俗に反し、または管理人によって承認すべきでないと認められるもの
            </StyledBody>
            <Heading>著作権</Heading>
            <StyledBody>
              当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。
              記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
              また、当サイトのコンテンツ（記事・画像・その他プログラム）について、許可なく転載することを禁じます。引用の際は、当ブログへのリンクを掲載するとともに、転載であることを明記してください。
            </StyledBody>
            <Heading>免責事項</Heading>
            <StyledBody>
              当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
              当サイトのコンテンツや情報につきましては、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっている事もございます。
              当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。また、本免責事項、および当サイトに掲載しているすべての記事は、予告なしに変更・削除されることがあります。
              当サイトは日本国内の居住者が利用するために作成されています。その他の国の居住者を対象としておりませんので、予めご了承ください。
            </StyledBody>
            <Heading>プライバシーポリシーの変更について</Heading>
            <StyledBody>
              当ブログは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
              修正された最新のプライバシーポリシーは常に本ページにて開示されます。
            </StyledBody>
            <StyledBody>
              <b>掲載（更新）日： 2024年 5月 3日</b>
            </StyledBody>
          </HeadingLevel>
        </HeadingLevel>
      </div>
    </div>
  );
}
